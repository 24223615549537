<template>
  <b-container>
    <h4>404 - What are you doing here? :-)</h4>
  </b-container>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>